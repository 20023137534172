<template>

    <div class="card-chat">

        <div class="messaging">
            <div class="inbox_people animated-fast fadeInLeft" :class="{small : inboxSmall}">
                <div class="heading_srch">
                    <i class="fa" v-on:click="inboxSmall = !inboxSmall" :class="[inboxSmall ?  'fa-arrow-square-right' : 'fa-arrow-square-left'] "></i>
                    <div class="recent_heading" :class="{hide : inboxSmall}">
                        <p>{{ $t('chat.view.conversations.recent') }}</p>
                    </div>

                    <div class="srch_bar">
                        <form class="stylish-input-group" @submit.prevent="searchUser(user_search)">
                            <input type="text" class="search-bar" v-model="user_search" placeholder="Search">
                            <span class="input-group-addon">
                                 <button type="submit"> <i class="fa fa-search" aria-hidden="true"></i> </button>
                            </span>
                        </form>
                    </div>

                </div>
                <div class="inbox_chat" ref="conversations" :class="{hide : inboxSmall}">

                    <div v-if="searchResults" class="p-0">
                        <a class="px-4 py-1 d-block text-secondary border-bottom" href="" v-for="result in searchResults" :key="result.name" @click.prevent="createConversation(result.username)">
                            <i class="fad fa-user mr-2"></i>{{result.username}} <span v-if="result.plants">| {{result.plants }} <i class="fad fa-seedling"></i></span> <br/>
                            <span class="text-muted small">{{result.created_at | moment('calendar')}}</span>
                        </a>
                    </div>

                    <div v-if="conversations.length">
                        <div :class="{active_chat: currentConversation.id === group.conversation_id}"
                             class="chat_list"
                             v-for="(group) in conversations"
                             :key="group.conversation_id"
                             v-on:click="updateConversationId(group.conversation)">
                            <div class="chat_people">
                                <div class="chat_img"><img :src="group.conversation.participants[0].avatar_url" alt="pic"></div>
                                <div class="chat_ib"
                                     :class="{chat_new_message: group.conversation.last_message && !group.conversation.last_message.is_seen && !group.conversation.last_message.is_sender}">
                                    <h5>
                                        <template v-for="participant in group.conversation.participants">
                                            <template v-if="participant.name !== $store.getters.user('name')">{{participant.name}}</template>
                                        </template>

                                        <span
                                                class="chat_date"
                                                v-if="group.conversation.last_message">
                                                {{$moment.utc(group.conversation.last_message.created_at).fromNow()}}
                                            </span>
                                    </h5>
                                    <p v-if="group.conversation.last_message">
                                        {{group.conversation.last_message.sender.name}}: {{group.conversation.last_message.body}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <router-view></router-view>

        </div>
    </div>
</template>
<script>

    export default {
        components: {},
        data() {
            return {
                user_search: '',
                inboxSmall: false,
                conversations: [],
                currentConversation: {
                    id: this.$store.state.chat.lastConversationId
                },
                searchResults: [],
                lazyLoading: false,
                lastConversationAmount: 1,
                page: 1
            }
        },
        mounted() {

            this.$title.set('Conversations');

            this.$api.get('chat/conversations').then((response) => {
                this.conversations = response.data;
                this.onScroll()
            });
            this.$api.messageListener(message => {
                this.updateConversations(message);
            });

            this.$refs.conversations.addEventListener("scroll", this.onScroll);
        },
        beforeDestroy() {
            if (this.$refs.conversations) { //escape error if navigation aborted and conversation not mounted
                this.$refs.conversations.removeEventListener("scroll", this.onScroll);
            }
        },
        updated() {
            this.lazyLoading = false;
        },
        methods: {
            onScroll() {
                if (this.$refs.conversations.scrollHeight - this.$refs.conversations.scrollTop <= this.$refs.conversations.clientHeight + 100 && !this.lazyLoading && this.lastConversationAmount > 0) {
                    this.lazyLoading = true;
                    this.page++;
                    this.$api.get('chat/conversations', {page: this.page}).then((response) => {
                        this.lastConversationAmount = response.data.length;
                        this.conversations = this.conversations.concat(response.data);
                    });
                }
            },
            updateConversationId(conversation) {

                if (window.innerWidth <= 650) {
                    this.inboxSmall = true;
                }

                if (this.currentConversation.id !== conversation.id) {
                    this.$store.commit('setCurrentConversation', conversation.id);
                    this.currentConversation.id = conversation.id;

                    if (conversation.last_message) {
                        conversation.last_message.is_seen = 1;
                    }

                    this.$router.push({name: 'Chat:Conversation', params: {conversationId: conversation.id}});
                }
            },
            updateConversations(data) {
                let id = data.conversation_id;
                let index = this.conversations.findIndex((element) => element.conversation_id === id);
                this.conversations[index].conversation.last_message = data;
            },
            searchUser(username) {
                this.$api.get('chat/search-user', {
                    query: username
                }).then(response => {
                    this.searchResults = response.data;
                })
            },
            createConversation(username) {
                this.$api.post('chat/conversations', {name: username}).then(response => {
                    if (this.$store.state.chat.lastConversationId !== response.data.conversation.id) {
                        this.$store.commit('setCurrentConversation', response.data.conversation.id);
                        this.$router.push({name: 'Chat:Conversation', params: {conversationId: response.data.conversation.id}});
                    }
                });
            },
        }
    }
</script>